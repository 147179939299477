import Claymore1 from "./claymore (1).webp";
import Claymore2 from "./claymore (2).webp";
import Claymore3 from "./claymore (3).webp";
import Claymore4 from "./claymore (4).webp";
import Claymore5 from "./claymore (5).webp";
import Claymore6 from "./claymore (6).webp";
import Claymore7 from "./claymore (7).webp";
import Claymore8 from "./claymore (8).webp";
import Claymore9 from "./claymore (9).webp";
import Claymore10 from "./claymore (10).webp";
import Claymore11 from "./claymore (11).webp";
import Claymore12 from "./claymore (12).webp";
import Claymore13 from "./claymore (13).webp";
import Claymore14 from "./claymore (14).webp";
import Claymore15 from "./claymore (15).webp";
import Claymore16 from "./claymore (16).webp";

const claymore = [
  Claymore1,
  Claymore2,
  Claymore3,
  Claymore4,
  Claymore5,
  Claymore6,
  Claymore7,
  Claymore8,
  Claymore9,
  Claymore10,
  Claymore11,
  Claymore12,
  Claymore13,
  Claymore14,
  Claymore15,
  Claymore16,
];

export default claymore;
