import React from "react";
import "./district9.scss";
import logodistrict9 from "../../../asset/utama/district9-logo.webp";
import Cardrumah from "../../../component/cardrumah/cardrumah";
import district9 from "./district.json";
import claymore from "../../../asset/distict9/claymore/claymore";
import dempsey from "../../../asset/distict9/dempsey/dempsey";
const imageMap = {
  claymore,
  dempsey,

  // Add other image arrays here if you have more sets
};

const District9 = () => {
  return (
    <div className="district9">
      <div className="container-district9">
        <div className="header-district9">
          <div className="logo">
            <img src={logodistrict9} alt="district-9" />
          </div>
        </div>
        <div className="cardhome-container">
          {district9.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default District9;
