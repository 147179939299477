import Canna1 from "./canna (1).webp";
import Canna2 from "./canna (2).webp";
import Canna3 from "./canna (3).webp";
import Canna4 from "./canna (4).webp";
import Canna5 from "./canna (5).webp";
import Canna6 from "./canna (6).webp";
import Canna7 from "./canna (7).webp";
import Canna8 from "./canna (8).webp";
import Canna9 from "./canna (9).webp";
import Canna10 from "./canna (10).webp";
import Canna11 from "./canna (11).webp";
import Canna12 from "./canna (12).webp";
import Canna13 from "./canna (13).webp";

const canna = [
  Canna1,
  Canna2,
  Canna3,
  Canna4,
  Canna5,
  Canna6,
  Canna7,
  Canna8,
  Canna9,
  Canna10,
  Canna11,
  Canna12,
  Canna13,
];

export default canna;
