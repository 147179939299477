import Tanzanite1 from "./tanzanite (1).webp";
import Tanzanite2 from "./tanzanite (2).webp";
import Tanzanite3 from "./tanzanite (3).webp";
import Tanzanite4 from "./tanzanite (4).webp";
import Tanzanite5 from "./tanzanite (5).webp";
import Tanzanite6 from "./tanzanite (6).webp";
import Tanzanite7 from "./tanzanite (7).webp";
import Tanzanite8 from "./tanzanite (8).webp";
import Tanzanite9 from "./tanzanite (9).webp";
import Tanzanite10 from "./tanzanite (10).webp";

const tanzanite = [
  Tanzanite1,
  Tanzanite2,
  Tanzanite3,
  Tanzanite4,
  Tanzanite5,
  Tanzanite6,
  Tanzanite7,
  Tanzanite8,
  Tanzanite9,
  Tanzanite10,
];

export default tanzanite;
