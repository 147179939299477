import React from "react";
import "./devider.scss";
import Logo from "../../asset/utama/cls-logow.webp";

const devider = () => {
  return (
    <div className="devider">
      <div className="container-devider">
        <div className="logocitraland">
          <img src={Logo} alt="logo" />
        </div>
        <div className="header-devider">
          <div className="judul-devider">Kenapa Harus Citraland Surabaya?</div>
          <div className="desk-devider">
            Citraland Surabaya adalah kawasan perumahan eksklusif yang
            dikembangkan oleh Ciputra Group di Surabaya, berikut beberapa
            keuntungan yang ada di Citraland The Singapore of Surabaya:
          </div>
          <div className="list">
            <ul>
              <li>Lokasi Strategis</li>
              <li>Kemewahan dan Kualitas Hidup</li>
              <li>Kemurnian dan Kebersihan</li>
              <li>Kemodernan dan Kualitas Hidup</li>
              <li>Kemudahan Aksesibilitas</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default devider;
