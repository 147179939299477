import React from "react";
import "./produk.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logoimages from "../../asset/utama/logo.js";
import District9 from "./district9/district9.jsx";
import Diamonhill from "./diamonhill/diamonhill.jsx";
import Buonavista from "./buonavista/buonavista.jsx";

const produk = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyload: true,
    autoplay: true,
    autoplayspeed: 1500,
  };
  return (
    <div id="produk" className="produk">
      <div className="p">
        <div className="container-produk">
          <div className="header-produk">
            <div className="judul-produk">Our Residential's</div>
            <div className="slider-logo">
              <Slider {...settings}>
                {logoimages.map((image, index) => (
                  <img
                    className="kartugambar"
                    key={index}
                    src={image}
                    alt="fasilitas"
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <District9 />
      <Diamonhill />
      <Buonavista />
    </div>
  );
};

export default produk;
