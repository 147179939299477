import Maple1 from "../../asset/maple/maple (1).png";
import Maple2 from "../../asset/maple/maple (2).png";
import Maple3 from "../../asset/maple/maple (3).png";
import Maple4 from "../../asset/maple/maple (4).png";
import Maple5 from "../../asset/maple/maple (5).png";
import Maple6 from "../../asset/maple/maple (6).png";
import Maple7 from "../../asset/maple/maple (7).png";
import Maple8 from "../../asset/maple/maple (8).png";
import Maple9 from "../../asset/maple/maple (9).png";
import Maple10 from "../../asset/maple/maple (10).png";
import Maple11 from "../../asset/maple/maple (11).png";
import Maple12 from "../../asset/maple/maple (12).png";

const images = [
  Maple1,
  Maple2,
  Maple3,
  Maple4,
  Maple5,
  Maple6,
  Maple7,
  Maple8,
  Maple9,
  Maple10,
  Maple11,
  Maple12,
];

export default images;
