import React from "react";
import "./newlaunch.scss";
import New1 from "./new1/new1.jsx";

const newlaunch = () => {
  return (
    <div className="container1">
      <div className="header-newlaunch">OTHER HOME IN AREA</div>
      <div id="newlaunch1" className="judul-newlaunch1">
        <div className="judul1">NEW LAUNCHING </div>
        <div className="judul2">NEW DEMPSEY HILL</div>
        <div className="judul3">AT CITRALAND UTARA SURABAYA</div>
        <New1 />
      </div>
    </div>
  );
};

export default newlaunch;
