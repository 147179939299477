import Ixora1 from "./ixora (1).webp";
import Ixora2 from "./ixora (2).webp";
import Ixora3 from "./ixora (3).webp";
import Ixora4 from "./ixora (4).webp";
import Ixora5 from "./ixora (5).webp";
import Ixora6 from "./ixora (6).webp";
import Ixora7 from "./ixora (7).webp";
import Ixora8 from "./ixora (8).webp";
import Ixora9 from "./ixora (9).webp";
import Ixora10 from "./ixora (10).webp";
import Ixora11 from "./ixora (11).webp";
import Ixora12 from "./ixora (12).webp";
import Ixora13 from "./ixora (13).webp";
import Ixora14 from "./ixora (14).webp";
import Ixora15 from "./ixora (15).webp";

const ixora = [
  Ixora1,
  Ixora2,
  Ixora3,
  Ixora4,
  Ixora5,
  Ixora6,
  Ixora7,
  Ixora8,
  Ixora9,
  Ixora10,
  Ixora11,
  Ixora12,
  Ixora13,
  Ixora14,
  Ixora15,
];

export default ixora;
