import React from "react";
import "./home.scss";
import Navbar from "../../section/navbar/navbar";
import Hero from "../../section/hero/hero";
import About from "../../section/about/about";
import Devider from "../../section/devider/devider";
import Produk from "../../section/produk/produk";
import Newlaunch from "../../section/newlaunch/newlaunch";
import Lokasi from "../../section/lokasi/lokasi";
import Footer from "../../section/footer/footer";
import Promo from "../../section/promo/promo";
import ScrollToHashElement from "../ScrollToHashElement";

const home = () => {
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Hero />
      <About />
      <Promo />
      <Devider />
      <Produk />
      <Newlaunch />
      <Lokasi />
      <Footer />
    </div>
  );
};

export default home;
