import React from "react";
import "./about.scss";
import { MdSecurity } from "react-icons/md";
import { PiParkDuotone, PiSolarPanelDuotone } from "react-icons/pi";

const about = () => {
  return (
    <div id="about" className="about">
      <div className="container-about">
        <div className="header-about">
          <div className="judul-about">
            CitraLand Surabaya The Singapore of Surabaya
          </div>
          <div className="desk-about">
            Cirtaland Surabaya adalah kota modern yang dibangun oleh Grup
            Ciputra di kawasan Sambikerep Surabaya. Masterplan Citraland
            dimaksudkan untuk menjadi sebuah kota yang mewadahi semua aktivitas
            perkotaan dengan banyak ruang terbuka untuk aktivitas publik. Ini
            akan membentuk gaya hidup penduduknya yang menyukai kehidupan alam.
            Cirtaland Surabaya, kebanggaan Grup Ciputra, adalah proyek kota baru
            terbesar di Indonesia. Konsep lingkungan yang tertata baik, standar
            infrastruktur dan utilitas yang prima, dan manajemen perkotaan yang
            rapi adalah alasan Citraland Surabaya disebut sebagai "Singapore of
            Surabaya."
          </div>
        </div>
        <div className="list-benefit">
          <div className="list">
            <ul>
              <li>
                <MdSecurity size="70px" />
                &nbsp;&nbsp;
                <span>24 Hour Security</span>
              </li>
              <li>
                <PiSolarPanelDuotone size="70px" />
                &nbsp;&nbsp;
                <span>Solar Home System</span>
              </li>
              <li>
                <PiParkDuotone size="70px" />
                &nbsp;&nbsp;
                <span>Landscape Garden</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default about;
