import Ambrosia1 from "./ambrosia (1).webp";
import Ambrosia2 from "./ambrosia (2).webp";
import Ambrosia3 from "./ambrosia (3).webp";
import Ambrosia4 from "./ambrosia (4).webp";
import Ambrosia5 from "./ambrosia (5).webp";
import Ambrosia6 from "./ambrosia (6).webp";
import Ambrosia7 from "./ambrosia (7).webp";
import Ambrosia8 from "./ambrosia (8).webp";
import Ambrosia9 from "./ambrosia (9).webp";
import Ambrosia10 from "./ambrosia (10).webp";
import Ambrosia11 from "./ambrosia (11).webp";
import Ambrosia12 from "./ambrosia (12).webp";
import Ambrosia13 from "./ambrosia (13).webp";
import Ambrosia14 from "./ambrosia (14).webp";
import Ambrosia15 from "./ambrosia (15).webp";

const ambrosia = [
  Ambrosia1,
  Ambrosia2,
  Ambrosia3,
  Ambrosia4,
  Ambrosia5,
  Ambrosia6,
  Ambrosia7,
  Ambrosia8,
  Ambrosia9,
  Ambrosia10,
  Ambrosia11,
  Ambrosia12,
  Ambrosia13,
  Ambrosia14,
  Ambrosia15,
];

export default ambrosia;
