import React from "react";
import "./buonavista.scss";
import logobuonavista from "../../../asset/utama/buonavista-logo.webp";
import Cardrumah from "../../../component/cardrumah/cardrumah";
import buonavista from "./buonavista.json";
import ixora from "../../../asset/buonavista/ixora/ixora";
import ambrosia from "../../../asset/buonavista/ambrosia/ambrosia";
import canna from "../../../asset/buonavista/canna/canna";
const imageMap = {
  ixora,
  ambrosia,
  canna,

  // Add other image arrays here if you have more sets
};

const Buonavista = () => {
  return (
    <div className="buonavista">
      <div className="container-buonavista">
        <div className="header-buonavista">
          <div className="logo">
            <img src={logobuonavista} alt="buonavista" />
          </div>
        </div>
        <div className="cardhome-container">
          {buonavista.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Buonavista;
