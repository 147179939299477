import Dempsey1 from "./dempsey (1).webp";
import Dempsey2 from "./dempsey (2).webp";
import Dempsey3 from "./dempsey (3).webp";
import Dempsey4 from "./dempsey (4).webp";
import Dempsey5 from "./dempsey (5).webp";
import Dempsey6 from "./dempsey (6).webp";

const dempsey = [Dempsey1, Dempsey2, Dempsey3, Dempsey4, Dempsey5, Dempsey6];

export default dempsey;
