import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=6281252875517&text=Halo+Merlyn%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(wa)%20https://www.cls-citralandsurabaya.com/&type=phone_number&app_absent=0"
          >
            <FloatingWhatsApp />
          </a>
        </Router>
      </header>
    </div>
  );
}

export default App;
